<template>
  <section>
   <head>
  <title>Fvl 2024</title>
   <meta charset="UTF-8">
   <meta name="viewport" content="width=device-width">

   </head>
       <section class="home" style="background-color:blue;overflow: hidden;">
         <div class="banner-back" style="filter:blur(30px);transform: scale(1.2);position: fixed;z-index:0;">
               <banner-background></banner-background>
               </div>

               <div class="dropdown">
                 <button class="dropbtn">EN</button>
                    <div class="dropdown-content">
                      <router-link :to="{ name:'Fvlhome_en' }"> <a href="#">EＮ</a></router-link>
                      <router-link :to="{ name:'Fvlhome' }">    <a href="#">繁體中文</a></router-link>
                     </div>
             </div>
              <div class="banner-box">
               <div class="banner">
                 <div class="date-img" style="opacity: 0;">
                   <!-- <ti-tle></ti-tle> -->
                 <!-- <img src="../assets/date.png" style="position: absolute;top: 0;right: 0;"> -->
                    <img src="../assets/date.svg" style="position: absolute;top: 0;left: -50%;">
                 </div>
                 <div class="banner-img">
               <img src="../assets/2024banner.png" style="position: absolute;bottom: 0;width: 100vw;z-index:2;" >
               <div class="pulse">
                 <span style="--i:0;"></span>
                 <span style="--i:1;"></span>
                 <span style="--i:2;"></span>
                 <span style="--i:3;"></span>
                 <span style="--i:4;"></span>
                 <span style="--i:5;"></span>
                 <span style="--i:6;"></span>
               </div>
               <!-- <dvi class="svg" style="background-image: url(../assets/2024fvlsvg.svg);position:absolute ;bottom: 0;z-index: 99;"></dvi> -->
               </div>
               <!-- <div class="banner-back" style="filter:blur(30px);transform: scale(1.2);position: fixed;z-index: -1;">
               <banner-background></banner-background>
               </div> -->
               </div>
           
             </div>

         

               <!-- <div class="fvl-logo"> -->
                 <!-- <img class="fvl-logo-img" src="../assets/FVL.png"> -->
                 <!-- </div> -->



                 <!-- ///origin-slider -->
                 <!-- <TransitionGroup tag="div" class="banner" :name="transitionName">
                    <div class="page" v-for="(img,index) of imgs":key='index' v-show="index === show">
                        <template v-if="img.type === 'img'">
                      <img :src="img.src" style="height: 100%;height:100%;width:100%;object-fit: cover;" >
                      </template>

                      <template v-else-if="img.type === 'video'">
                        <iframe :src="img.src"
                         allowfullscreen=""
                         allow="autoplay" 
                         width="100%" height="100%"></iframe>
                       </template>
                    </div>    
                 </TransitionGroup> -->
                 <!-- ///origin-slider -->


                 <!-- <canvas> -->
                   <!-- <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div>
                   <div class="buble"></div> -->
                 <!-- </canvas> -->

             <div class="about-background" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
             <!-- <router-link :to="{ name:'Fvlhome_en' }">  <button class="english_btn">English</button></router-link> -->

        
               <!-- <div class="bl-test" style="margin: 0 auto;width:80%;height:40%;background:linear-gradient(to bottom, #ff2e77 0.1%,#1307b5 100%);position:absolute;border-radius: 30px;top:3rem;"></div> -->
                  <div class="fvl_about">
                        <div class="Fvl_zh" style="box-sizing: border-box;color:#ffffff;font-size: 2rem;font-weight: 700;line-height: 1.6;font-family: TPAC, Noto Sans TC, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;"><b>未來視覺實驗室</b></div>
                        <div class="fvl_en">FUTURE VISION LAB 2024</div>
             <!-- <div class="fvl_icon">
                 <div class="eyes">
                   <div class="eye_ball">
                      <span class="shut" id="shut"></span>
                         <div class="ball" >
                           <div class="reflect">
                           </div>
                         </div>
                  </div>
                </div> -->
                     <!-- <p style="font-size:1.5rem;font-weight:bolder;margin-top: 0.5rem;">FUTURE <br>VISION <br>LAB</p> -->
              <!-- </div> -->
               <div class="about_fvl">
                   <p>At the beginning of 2020, C-LAB, Taiwan's contemporary cultural experimental site of Air China, established the "Technology Media Experimental Platform", which focuses on "media experimental innovation" and "social links". Through an organic laboratory format, it Domain, co-creation, and collaboration methods create a co-creation environment for culture and technology.

                    The "Technology Media Experimental Platform" first launched an experimental exhibition project "FUTURE VISION LAB" that combines the fields of culture and technology. It designed a spherical structure "Dome" with a diameter of 12 meters and a height of 6 meters through the concept of digital architecture.
From November 2020 to January 17, 2021, the experimental goal is to explore the visual limits of technological media, supplemented by image computing, creation, and software and hardware integration, in an attempt to outline a cross-domain vision for future vision. The internal experimental plan of "Dome" will involve ultra-high-resolution image creation and an open display platform to create a fully immersive audio-visual experience space. It will use artificial intelligence and image generation, derivative art and high-speed computing platforms to create an experimental and innovative vision for future vision. 

The three-month exhibition plan invites many cutting-edge technology and art creators to work together to create 360-degree panoramic projection works, jointly depicting a fully immersive 8K image space, hoping to create future experimental and innovative visions, and provide them to the public free of charge Live experience.

The works created by domestic and foreign video teams provide different types of participation and performance activities on weekdays and weekends. In addition to viewing immersive video works on weekday evenings, the public can also participate in experimental audiovisual (Audio Visual) brought by cutting-edge technology artists on weekends. There are also activities such as performances and video installation performances, as well as astronomy stargazing workshops, sound healing meditation and yoga workshops, and video creation workshops suitable for parents and children.

With different exhibition activities and diverse visual experiences, I hope everyone can participate and experience the future world together.</p>
               </div>
            
      </div>

      <div class="fvl_tickets">
          <div class="exibition_info">
               <div class="sales_info">
                 <!-- <div class="title_background">
                   <p style="margin-bottom: 0 !important;">購票資訊</p>
                 </div> -->

                 <div class="tickets_en" style="text-transform: uppercase;color:#ff8c9f;font-size:10vw;font-family:Impact, fantasy;">tickets</div>
                   
                   <div class="about_event" >
                     <div class="event_date">
                     <p>Date</p>
                    <div class="date">
                     <p>9.30(一)</p>
                     <p> ——12.30(一)</p>
                     </div>
                     </div>
                     <br>
                     <p>location</p>
                     <p>C-LAB East Lawn</p>
                     <br>
                     <p style="font-weight:bolder;color:white;">HashTags</p>
                     <div class="hash-tags">
                     <div><p>#audiovisual </p></div>
                    <div> <p>#Technology media </p></div>
                    <div> <p>#futurevisionlab </p></div>
                     </div>
                   </div>
               </div>

               <div class="tickets">

                   <div class="ticket_buttons">
                       <button type="button" class="early-bird" :class="{'active':link == 'early-bird'}" v-on:click.prevent="link = 'early-bird'"><p style="font-size: 1.2rem;margin-bottom: 0 !important;">early bird </p></button>
                       <button type="button" class="pre-sale" :class="{'active':link == 'pre-sale'}" v-on:click.prevent="link = 'pre-sale'" @click="plus()"><p style="font-size: 1.2rem;margin-bottom: 0 !important;">pre-sale</p></button>
                       <button type="button" class="reqular-tickets" :class="{'active':link == 'regular'}" v-on:click.prevent="link = 'regular'"><p style="font-size: 1.2rem;margin-bottom: 0 !important;">regular</p></button>
                       <button type="button" class="students-tickets" :class="{'active':link == 'student'}" v-on:click.prevent="link = 'student'"><p style="font-size: 1.2rem;margin-bottom: 0 !important;">students</p></button>
                   </div>


                   <div class="tickets_info" >
                        <div v-if="link == 'early-bird'" class="ticket-price" >
                           <div class=" early-bird-info" style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;">
                              <div class="ticket-price-animate" style="width: 30%;">
                                   <p style="font-size:1.2rem;font-weight:bolder;color: black;white-space:nowrap;">PRICE</p> 
                              </div>
                              <div class="early-bird-price" style="width:70%;font-size: 1.2rem;">
                                <p style="width:70%;left: 0;">$200</p>
                              </div>
                          </div>
                        </div>


                        <div v-else-if="link == 'pre-sale'" class="ticket-price">
                         <div class=" pre-sale-info" style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;">
                              <div class="ticket-price-animate" style="width: 30%;">
                               <p style="font-size:1.2rem;font-weight:bolder;color: black;width:30%;white-space:nowrap;">PRICE</p> 
                              </div>
                              <div class="pre-sale-price" style="width:70%;font-size: 1.2rem;">
                                   <p style="width:70%;left: 0;">$220</p>
                              </div>
                         </div>
                       </div> 

                        <div v-else-if="link == 'regular'" class="ticket-price" >
                         <div class=" regular-info" style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;">
                           <div class="ticket-price-animate" style="width: 30%;">
                           <p style="font-size:1.2rem;font-weight:bolder;color: black;width:30%;white-space:nowrap;">PRICE</p> 
                           </div>
                           <div class="pre-sale-price" style="width:70%;font-size: 1.2rem;">
                           <p style="width:70%;left: 0;">$250</p>
                           </div>
                           </div>
                        </div>
                        <div v-else-if="link == 'student'" class="ticket-price">
                         <div class=" studente-info" style="width: 100%;display: flex;flex-direction: row;justify-content: flex-start;">
                           <div class="ticket-price-animate" style="width: 30%;">
                           <p style="font-size:1.2rem;font-weight:bolder;color: black;width:30%;white-space:nowrap;">PRICE</p> 
                           </div>
                           <div class="pre-sale-price" style="width:70%;font-size: 1.2rem;">
                           <p style="width:70%;left: 0;">$200</p>
                           </div>
                           </div>
                        </div>

                        <hr style='background-color:black; height:0.5px; border:none;opacity: 0.5;'>

                         <div class="ticket-time">
       
                           <div v-if="link == 'early-bird'" style="width:100%;left: 0;display: flex;justify-content: flex-start">
                              <div class="ticket-time-animate" style="width: 30%;">
                           <p style="font-size:1.2rem;font-weight:bolder;color: black;">SALES TIME</p>
                           </div>
                             <div class="early-bird-time" style="width:70%;font-size: 1.2rem;">
                               <p>2024.7.26 - 2024.8.24</p>
                             </div>
                           </div>

                           <div v-else-if="link == 'pre-sale'" style="width:100%;left: 0;display: flex;justify-content: flex-start">
                              <div class="ticket-time-animate" style="width: 30%;">
                           <p style="font-size:1.2rem;font-weight:bolder;color: black;">SALES TIME</p>
                           </div>
                             <div class="pre-sale-time"  style="width:70%;font-size: 1.2rem;">
                               <p>2024.7.26 - 2024.8.24</p>
                             </div>
                           </div>

                           <div v-else-if="link == 'regular'" style="width:100%;left: 0;display: flex;justify-content: flex-start">
                              <div class="ticket-time-animate" style="width: 30%;">
                           <p style="font-size:1.2rem;font-weight:bolder;color: black;">SALES TIME</p>
                           </div>
                             <div class="regular-time" style="width:70%;font-size: 1.2rem;">
                               <p>2024.7.26 - 2024.8.24</p>
                             </div>
                           </div>

                           <div v-else-if="link == 'student'" style="width:100%;left: 0;display: flex;justify-content: flex-start">
                              <div class="ticket-time-animate" style="width: 30%;">
                           <p style="font-size:1.2rem;font-weight:bolder;color: black;">SALES TIME</p>
                           </div>
                             <div class="student-time" style="width:70%;font-size: 1.2rem;">
                               <p>2024.7.26 - 2024.8.24</p>
                             </div>
                           </div>

                           
                           
                           <!-- <div v-else-if="link == 'pre-sale'" style="width:70%;left: 0;"><div class="pre-sale-time"><p>2024.7.28 - 2024.9.15</p></div></div> -->
                           <!-- <div v-else-if="link == 'regular'" style="width:70%;left: 0;"><div class="regular-time"><p>2024.8.28 - 2024.10.15</p></div></div> -->
                           <!-- <div v-else-if="link == 'student'" style="width:70%;left: 0;"><div class="student-time"><p>2024.6.28 - 2024.11.15</p></div></div> -->
                         </div>
                         <hr style='background-color:black; height:0.5px; border:none;opacity: 0.5;'>
 
                         <div v-if="link == 'early-bird'" >
                           <div class="sales-info" style="width: 100%;display: flex;justify-content: flex-start;flex-direction: row;"> 
                              <div class="sales-enter-animate" style="width:30% ;">
                                  <p style="font-size:1.2rem;font-weight:bolder;color: black;">SALES INFO</p>
                              </div>
                              <div class="sales-detail" style="width: 70%;font-size: 1.2rem;">
                                 <p style="left:0;">Please purchase electronic tickets on the ibon ticketing system website</p>
                              </div>
                           </div>
                         </div>

                         <div v-if="link == 'pre-sale'" >
                           <div class="sales-info" style="width: 100%;display: flex;justify-content: flex-start;flex-direction: row;"> 
                              <div class="sales-enter-animate" style="width:30% ;">
                                  <p style="font-size:1.2rem;font-weight:bolder;color: black;">SALES INFO</p>
                              </div>
                              <div class="sales-detail" style="width: 70%;font-size: 1.2rem;">
                                 <p style="left:0;">Please purchase electronic tickets on the ibon ticketing system website</p>
                              </div>
                           </div>
                         </div>

                         <div v-if="link == 'regular'" >
                           <div class="sales-info" style="width: 100%;display: flex;justify-content: flex-start;flex-direction: row;"> 
                              <div class="sales-enter-animate" style="width:30% ;">
                                  <p style="font-size:1.2rem;font-weight:bolder;color: black;">SALES INFO</p>
                              </div>
                              <div class="sales-detail" style="width: 70%;font-size: 1.2rem;">
                                 <p style="left:0;">Please purchase electronic tickets on the ibon ticketing system website</p>
                              </div>
                           </div>
                         </div>

                         <div v-if="link == 'student'" >
                           <div class="sales-info" style="width: 100%;display: flex;justify-content: flex-start;flex-direction: row;"> 
                              <div class="sales-enter-animate" style="width:30% ;">
                                  <p style="font-size:1.2rem;font-weight:bolder;color: black;">SALES INFO</p>
                              </div>
                              <div class="sales-detail" style="width: 70%;font-size: 1.2rem;">
                                 <p style="left:0;">Please purchase electronic tickets on the ibon ticketing system website</p>
                              </div>
                           </div>
                         </div>

                        <hr style='background-color:black;height:0.1px; border:none;opacity: 0.5;'>

                          <div class="notice">
                           <p style="font-size:1.2rem;font-weight:bolder;color: black;width:100%;padding:0;margin-bottom:1rem;">NOTICE</p>
                          <p><b>◉</b> Part of the performance contains strong flashes, please consider the audio and video experiment before entering.。<br>
                           <b>◉</b>   Since the event venue has a mirrored floor, it is recommended to wear "trousers" to enter and watch the event.<br>
                           <b>◉</b>   Ｐicnic mats and chairs are not allowed. If you have other needs, please contact the front desk staff for assistance.<br>
                           <b>◉</b>   Photos and live action shots can be taken during the broadcast, but the use of flash is prohibited.<br>
                           <b>◉</b>   The organizer reserves the right to adjust and change the event.</p>
                          </div>
                   </div>
               </div>

               <!-- <div class="bg-test" style="width:100vw;height:100%;background-image:url(../imgs/test.jpg);   background-size:contain; background-repeat: no-repeat;  background-position:right bottom;position: absolute;z-index:0;top: 0;left: 0;opacity: 0.1;"></div> -->
                 <!-- <div class="bg" style="width:100%;height:100%;background-position: right bottom;position: absolute;z-index: 0;top: 0;left: 0;opacity: 0.1;background-color: aqua;">
                  <ani-back></ani-back>
                 </div> -->
            </div>
          </div>
        
          <div class="background2">
             <back-ground></back-ground>
             <!-- <back-ground></back-ground> -->
         </div>

         <div class="background3">
             <back-ground2></back-ground2>
         </div>
         </div>
<!-- 
          <div class="banner-back" style="filter:blur(30px);transform: scale(1.2);">
               <banner-background></banner-background>
               </div> -->
     
          
                   <!-- <div class="previewbox" style="display: flex;flex-direction: column;">
                            <article class="preview" style="width:80%;height:auto;">
                            
                                  <div class="preview__thumbnail"></div>
                                    <div class="preview__text">
                                       <h2 class="preview__text__header" style="color: white;">FUTURE VISION LAB</h2>
                                          <p class="preview__text__description" style="color: white;"> Upgrade to premium speakers that are phenomenally built to deliver trulyremarkable sound.</p>
                                           <button class="preview__text__link__btn default-btn">Read More</button>
                                     
                                    </div>
            
                             </article>


                         <article class="preview2"  style="width:80%;height:auto;">
                           <div class="preview__thumbnail"></div>
                              <div class="preview__text">
                                 <h2 class="preview__text__header" style="color: white;">FUTURE VISION LAB</h2>
                                      <p class="preview__text__description" style="color: white;">
                                         Upgrade to premium speakers that are phenomenally built to deliver truly
                                         remarkable sound.
                                     </p>
                                  <router-link to="2018" class="preview__text__link">
                                      <button class="preview__text__link__btn default-btn">
                                         Read More
                                      </button>
                                  </router-link>
                              </div>
                          </article>


                          <article class="preview3"  style="width:80%;height:auto;">
                             <div class="preview__thumbnail"></div>
                                 <div class="preview__text">
                                    <h2 class="preview__text__header" style="color: white;">FUTURE VISION LAB</h2>
                                       <p class="preview__text__description" style="color: white;">
                                         Upgrade to premium speakers that are phenomenally built to deliver truly
                                         remarkable sound.
                                       </p>
                                     <router-link to="2018" class="preview__text__link">
                                          <button class="preview__text__link__btn default-btn">
                                            Read More 
                                          </button>
                                     </router-link>
                                  </div>
                          </article>

                      </div> -->
           <div class="program-list">
                       <!-- <div class="title_background" > <p style="font-weight: 700;margin-bottom: 0 !important;">節目場次</p></div> -->
                       <div class="tickets_en" style="text-transform: uppercase;color:#ff8c9f;font-size:10vw;font-family:Impact, fantasy;">Rrogram</div>
                        <!-- <img src="imgs/list.jpg" style="margin-top: 1rem;border-radius: 25px;padding:2rem;background-color: white;"> -->


                 <!-- 10月 -->
                  <div class="table" v-if="ShowOctober">
                   <tr>
                     <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                   </tr>


                  <tr>
                   <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">10/22(Tue.)</th>
                   <td scope="col" style="padding:20px;vertical-align:middle">19:00</td>
                   <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;width: 100%;">黃子祐｜《沉浸影像工作坊》Part I <br>Revlis HUANG｜Dome immersive video workshop via TouchDesigner- Part I</td>
                  </tr>


                 <tr>
                   <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">10/25(Fri.)</th>
                   <td scope="col" style="padding:20px;vertical-align:middle">13:30、17:30</td>
                   <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;border: none;width: 100%;border-end-end-radius: 25px;">黃子祐｜《沉浸影像工作坊》Part II <br>Revlis HUANG｜Dome immersive video workshop via TouchDesigner- Part II</td>
                 </tr>
                  </div>

                  
                  <!-- //// -->

                  <!-- 10月第一週 -->
                  <div class="table" v-if="ShowOctoberweek">
                   <tr>
                     <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本週節目</td>
                   </tr>


                  <tr>
                   <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">10/22(Tue.)</th>
                   <td scope="col" style="padding:20px;vertical-align:middle">19:00</td>
                   <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;width: 100%;">黃子祐｜《沉浸影像工作坊》Part I <br>Revlis HUANG｜Dome immersive video workshop via TouchDesigner- Part I</td>
                  </tr>


                 <tr>
                   <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">10/25(Fri.)</th>
                   <td scope="col" style="padding:20px;vertical-align:middle">13:30、17:30</td>
                   <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;border: none;width: 100%;">黃子祐｜《沉浸影像工作坊》Part II <br>Revlis HUANG｜Dome immersive video workshop via TouchDesigner- Part II</td>
                 </tr>
                  </div>
                  <!-- //////// -->

                  <div class="table"  v-if="ShowOctober">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">11月節目</td>
                          </tr>

                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">11/2(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">14:00-22:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">《FUTURE VISION LAB 2024 作品輪映》
                           *本日活動響應台北市主辦之「白晝之夜」，詳細入場方式請見主辦單位公告與說明FUTURE VISION LAB 2024 - Works Screening
                           *Today's event is in support of the "Nuit Blanche Taipei". For detailed entry information, please refer to officila website of FUTURE VISION LAB.</td>
                           </tr>
                        
                           <!-- 11/3 -->
                           <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/3(Sat.)</th>
                             <!-- <td scope="col" style="border: 1px solid #000;padding:5px;">14:00-22:00</td>
                             <td scope="col" style="border: 1px solid #000;padding:5px;">《FUTURE VISION LAB 2024 作品輪映》
                           *本日活動響應台北市主辦之「白晝之夜」，詳細入場方式請見主辦單位公告與說明FUTURE VISION LAB 2024 - Works Screening
                           *Today's event is in support of the "Nuit Blanche Taipei". For detailed entry information, please refer to officila website of FUTURE VISION LAB.</td> -->
                         
                           <tr style="">
                             <td style="border-top: none; padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome<br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins
                             Ina CHEN x Calvin SIN x LU Te-Hsing<br>｜4993 Feet Under <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome<br>長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 minsKazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 minsIna CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under<br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 minsSergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                     
                           </tr>
                         </tr>




                         <!-- 11/10 -->
                         <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/10(Sun.)</th>

                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins
                             Ina CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under  <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome <br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">程威誌 x 比林 x 劉家禹｜《Module IN DOME》｜15 minsCHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Screening) <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                           
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 mins CHUANG Ho｜Demo: Dome<br>長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 mins Kazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                     
                           </tr>
                         </tr>


                           <!-- 11/15 -->
                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/15(Fri.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">16:30-18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x 關鍵路徑｜ 《我記得住機器記住我記住的》（展覽）C-LAB x Critical Path｜BODY.DATA.LOOP (Exhibition)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">19:30</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x 關鍵路徑｜ 《我記得住機器記住我記住的》（現場演出）｜40 minsC-LAB x Critical Path｜BODY.DATA.LOOP (Live Performance)</td>
                          
                           </tr>
                       
                           </tr>

                           <!-- 11/16 -->
                           <tr>
                             <th rowspan="6" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/16(Sat.)</th>

                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey <br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins Ina CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under<br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia <br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                           
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 mins CHUANG Ho｜Demo: Dome<br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                     
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 19:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 mins Kazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                     
                           </tr>
                         </tr>
                         <!-- 11/17 -->
                         <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/17(Sun.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">14:00-15:15</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">《未來視覺 X DOME— 藝術家分享講座》場次一｜陳蘇楊、冼筱然、盧德昕、謝爾蓋．普羅科菲耶夫、吳克軍、蔡奇宏（主持） Unboxing DOME- Artist’s Talk #1｜Ina CHEN, Calvin SIN, LU Te-Hsing, Sergey PROKOFYEV, WU Ke-Jyun, Warrick TSAI (Moderator) </td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00-17:15</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">《未來視覺 X DOME— 藝術家分享講座》場次二｜莊禾、蕭力綺、吳秉聖、蔡奇宏（主持） Unboxing DOME- Artist’s Talk #2｜CHUANG Ho, HSIAO Li-Chi, WU Ping-Sheng, Warrick TSAI (Moderator) </td>
                          
                           </tr>
                       
                           </tr>

                           <!-- 11/22 -->
                           <tr>
                             <th rowspan="2" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/22(Fri.)</th>
                             <tr style="border: 1px solid #000;">
                               <td style=" padding:20px;vertical-align:middle">19:00</td>
                               <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance) </td>
                             </tr>
                           </tr>

                           <!-- 11/23 -->
                          <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/23(Sat.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">12:00-18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（展覽） Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Exhibition)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance)</td>
                               </tr>
                           </tr>

                           <!-- 11/24 -->

                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/24(Sun.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">12:00-18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（展覽） Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Exhibition)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance)</td>
                               </tr>
                           </tr>
                                 <!-- 11/29 -->

                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/29(Fri.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">19:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">20:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                           </tr>
                                    <!-- 11/30 -->
                                    <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">11/30(Sat.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">15:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">16:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                                 
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;border: none;border-end-end-radius: 25px;">吳承儒｜《認夢》（現場演出）｜15 mins WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                               </tr>
                           </tr>
                     </div>


                  <div class="table"  v-if="ShowOctober">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">12月節目</td>
                          </tr>

                          <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">12/1(Sun.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins <br>WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins <br>WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                          
                           </tr>
                       
                           </tr>
                        
                           <!-- 12/6 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/6(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">19:30</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x KAMS｜《未來視覺派對 #2》｜60 mins C-LAB x KAMS｜Future Vision Party #2 (Live Performance)</td>
                           </tr>
                      
                         <!-- 12/7 -->
                         <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/7(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">19:30</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x KAMS｜《未來視覺派對 #2》｜60 mins C-LAB x KAMS｜Future Vision Party #2 (Live Performance)</td>
                           </tr>
                           <!-- 12/13 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/13(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">16:00-20:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production </td>
                           </tr>
                           <!-- 12/14 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/14(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">12:00-19:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production  </td>
                           </tr>
                         <!-- 12/15 -->
                          <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color: blue;color: white;">12/15(Sun.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">12:00-19:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production  </td>
                           </tr>

                           <!-- 12/21 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color: blue;color: white;">12/21(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">場次待確認</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">財團法人數位藝術基金會｜《諸神黃昏》｜20 mins Digital Art Foundation｜DOME of the Gods</td>
                           </tr>

                           <!-- 12/22 -->
                          <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">12/22(Sun.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">14:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－清華大學藝術學院學士班 / 科藝所成果呈現》｜60 mins  Immersive Audiovisual Workshop - The Joint Presentation: Interdisciplinary Program of Technology and Art, National Tsing Hua University</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">15:30</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－實踐大學媒傳系成果呈現》｜60 mins Immersive Audiovisual Workshop - The Joint Presentation: Department of Communications Design, Shih Chien University</td>
                               </tr>

                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">17:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－清華大學藝術學院學士班 / 科藝所成果呈現》｜60 mins  Immersive Audiovisual Workshop - The Joint Presentation: Interdisciplinary Program of Technology and Art, National Tsing Hua University</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle;">18:30</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;border: none;border-end-end-radius: 25px;"> 《沉浸式影音工作坊－實踐大學媒傳系成果呈現》｜60 mins Immersive Audiovisual Workshop - The Joint Presentation: Department of Communications Design, Shih Chien University</td>
                               </tr>
                           </tr> 
                     </div>

                     
       <!--10月結束 ------------------------------------------------------------------------------------------------------------------------------------------------ -->


                     <div class="table"  v-if="ShowNovember">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">11月節目</td>
                          </tr>

                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">11/2(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">14:00-22:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">《FUTURE VISION LAB 2024 作品輪映》
                           *本日活動響應台北市主辦之「白晝之夜」，詳細入場方式請見主辦單位公告與說明FUTURE VISION LAB 2024 - Works Screening
                           *Today's event is in support of the "Nuit Blanche Taipei". For detailed entry information, please refer to officila website of FUTURE VISION LAB.</td>
                           </tr>
                        
                           <!-- 11/3 -->
                           <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/3(Sat.)</th>
                             <!-- <td scope="col" style="border: 1px solid #000;padding:5px;">14:00-22:00</td>
                             <td scope="col" style="border: 1px solid #000;padding:5px;">《FUTURE VISION LAB 2024 作品輪映》
                           *本日活動響應台北市主辦之「白晝之夜」，詳細入場方式請見主辦單位公告與說明FUTURE VISION LAB 2024 - Works Screening
                           *Today's event is in support of the "Nuit Blanche Taipei". For detailed entry information, please refer to officila website of FUTURE VISION LAB.</td> -->
                         
                           <tr style="">
                             <td style="border-top: none; padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome<br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins
                             Ina CHEN x Calvin SIN x LU Te-Hsing<br>｜4993 Feet Under <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome<br>長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 minsKazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 minsIna CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under<br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 minsSergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                     
                           </tr>
                         </tr>




                         <!-- 11/10 -->
                         <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/10(Sun.)</th>

                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins
                             Ina CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under  <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome <br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">程威誌 x 比林 x 劉家禹｜《Module IN DOME》｜15 minsCHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Screening) <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                           
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 mins CHUANG Ho｜Demo: Dome<br>長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 mins Kazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                     
                           </tr>
                         </tr>


                           <!-- 11/15 -->
                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/15(Fri.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">16:30-18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x 關鍵路徑｜ 《我記得住機器記住我記住的》（展覽）C-LAB x Critical Path｜BODY.DATA.LOOP (Exhibition)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">19:30</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x 關鍵路徑｜ 《我記得住機器記住我記住的》（現場演出）｜40 minsC-LAB x Critical Path｜BODY.DATA.LOOP (Live Performance)</td>
                          
                           </tr>
                       
                           </tr>

                           <!-- 11/16 -->
                           <tr>
                             <th rowspan="6" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/16(Sat.)</th>

                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey <br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins Ina CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under<br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia <br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                           
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 mins CHUANG Ho｜Demo: Dome<br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                     
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 19:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 mins Kazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                     
                           </tr>
                         </tr>
                         <!-- 11/17 -->
                         <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/17(Sun.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">14:00-15:15</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">《未來視覺 X DOME— 藝術家分享講座》場次一｜陳蘇楊、冼筱然、盧德昕、謝爾蓋．普羅科菲耶夫、吳克軍、蔡奇宏（主持） Unboxing DOME- Artist’s Talk #1｜Ina CHEN, Calvin SIN, LU Te-Hsing, Sergey PROKOFYEV, WU Ke-Jyun, Warrick TSAI (Moderator) </td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00-17:15</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">《未來視覺 X DOME— 藝術家分享講座》場次二｜莊禾、蕭力綺、吳秉聖、蔡奇宏（主持） Unboxing DOME- Artist’s Talk #2｜CHUANG Ho, HSIAO Li-Chi, WU Ping-Sheng, Warrick TSAI (Moderator) </td>
                          
                           </tr>
                       
                           </tr>

                           <!-- 11/22 -->
                           <tr>
                             <th rowspan="2" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/22(Fri.)</th>
                             <tr style="border: 1px solid #000;">
                               <td style=" padding:20px;vertical-align:middle">19:00</td>
                               <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance) </td>
                             </tr>
                           </tr>

                           <!-- 11/23 -->
                          <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/23(Sat.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">12:00-18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（展覽） Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Exhibition)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance)</td>
                               </tr>
                           </tr>

                           <!-- 11/24 -->

                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/24(Sun.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">12:00-18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（展覽） Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Exhibition)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance)</td>
                               </tr>
                           </tr>
                                 <!-- 11/29 -->

                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/29(Fri.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">19:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">20:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                           </tr>
                                    <!-- 11/30 -->
                                    <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">11/30(Sat.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">15:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">16:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                                 
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;border: none;border-end-end-radius: 25px;">吳承儒｜《認夢》（現場演出）｜15 mins WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                               </tr>
                           </tr>
                     </div>

                 <!-- 11月第一週 -->
                 <div class="table" v-if="ShowNovemberfirstweek">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本週節目</td>
                          </tr>

                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">11/2(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">14:00-22:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">《FUTURE VISION LAB 2024 作品輪映》
                           *本日活動響應台北市主辦之「白晝之夜」，詳細入場方式請見主辦單位公告與說明FUTURE VISION LAB 2024 - Works Screening
                           *Today's event is in support of the "Nuit Blanche Taipei". For detailed entry information, please refer to officila website of FUTURE VISION LAB.</td>
                           </tr>
                        
                           <!-- 11/3 -->
                           <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">11/3(Sat.)</th>
                             <!-- <td scope="col" style="border: 1px solid #000;padding:5px;">14:00-22:00</td>
                             <td scope="col" style="border: 1px solid #000;padding:5px;">《FUTURE VISION LAB 2024 作品輪映》
                           *本日活動響應台北市主辦之「白晝之夜」，詳細入場方式請見主辦單位公告與說明FUTURE VISION LAB 2024 - Works Screening
                           *Today's event is in support of the "Nuit Blanche Taipei". For detailed entry information, please refer to officila website of FUTURE VISION LAB.</td> -->
                         
                           <tr style="">
                             <td style="border-top: none; padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome<br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins
                             Ina CHEN x Calvin SIN x LU Te-Hsing<br>｜4993 Feet Under <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome<br>長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 minsKazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;border: none;border-end-end-radius: 25px;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 minsIna CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under<br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 minsSergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                     
                           </tr>
                         </tr>

                     </div>
                      <!-- /////// -->
                                   <!-- 11月第二週 -->
                                   <div class="table"  v-if="ShowNovembersecondweek">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                          </tr>

                         <!-- 11/10 -->
                         <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/10(Sun.)</th>

                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins
                             Ina CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under  <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 minsCHUANG Ho｜Demo: Dome <br>謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">程威誌 x 比林 x 劉家禹｜《Module IN DOME》｜15 minsCHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Screening) <br>莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey</td>
                           
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 mins CHUANG Ho｜Demo: Dome<br>長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 mins Kazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                     
                           </tr>
                         </tr>
                     </div>
                     <!-- ////// -->
                                           <!-- 第三週 -->

                                           <div class="table"  v-if="ShowNovemberthirdweek">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                          </tr>

                         <!-- 11/15 -->
                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/15(Fri.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">16:30-18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x 關鍵路徑｜ 《我記得住機器記住我記住的》（展覽）C-LAB x Critical Path｜BODY.DATA.LOOP (Exhibition)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">19:30</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x 關鍵路徑｜ 《我記得住機器記住我記住的》（現場演出）｜40 minsC-LAB x Critical Path｜BODY.DATA.LOOP (Live Performance)</td>
                          
                           </tr>
                       
                           </tr>

                           <!-- 11/16 -->
                           <tr>
                             <th rowspan="6" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/16(Sat.)</th>

                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莫爾托維奇＆希魯達｜《印度的他方：奧德賽》｜15 mins Murthovic & Thiruda｜Elsewhere in India: Odyssey <br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">陳蘇楊 x 冼筱然 x 盧德昕｜《油井之殤：鯨落4993尺》｜15 mins Ina CHEN x Calvin SIN x LU Te-Hsing｜4993 Feet Under<br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                          
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 17:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">謝爾蓋．普羅科菲耶夫｜《全球烏托邦中的在地反烏托邦》｜ 21 mins Sergey PROKOFYEV｜Local Dystopias in the Global Utopia <br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                           
                           
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 18:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">莊禾｜《影像雜技》｜8 mins CHUANG Ho｜Demo: Dome<br>程威誌 x 比林 x 劉家禹｜《Module IN DOME》（互動裝置版）｜15 mins CHENG WEI-ZHI x BILLAIN x Jeffrey LIU｜Module IN DOME (Interactive installation)</td>
                     
                           </tr>
                           <tr>
                             <td style="padding:20px;vertical-align:middle"> 19:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">長屋和哉 x 阿里．瑪哈穆．迪米萊爾 x 莫里斯．瓊斯｜《磐座》｜27 mins Kazuya NAGAYA x Ali Mahmut DEMIREL x Maurice JONES｜Iwakura</td>
                     
                           </tr>
                         </tr>
                         <!-- 11/17 -->
                         <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/17(Sun.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">14:00-15:15</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">《未來視覺 X DOME— 藝術家分享講座》場次一｜陳蘇楊、冼筱然、盧德昕、謝爾蓋．普羅科菲耶夫、吳克軍、蔡奇宏（主持） Unboxing DOME- Artist’s Talk #1｜Ina CHEN, Calvin SIN, LU Te-Hsing, Sergey PROKOFYEV, WU Ke-Jyun, Warrick TSAI (Moderator) </td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00-17:15</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">《未來視覺 X DOME— 藝術家分享講座》場次二｜莊禾、蕭力綺、吳秉聖、蔡奇宏（主持） Unboxing DOME- Artist’s Talk #2｜CHUANG Ho, HSIAO Li-Chi, WU Ping-Sheng, Warrick TSAI (Moderator) </td>
                          
                           </tr>
                       
                           </tr>
                     </div>

                     <!-- ////////// -->
                                           <!-- 第四週 -->
                 <div class="table"  v-if="ShowNovemberfourthweek">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                          </tr>

                           <!-- 11/22 -->
                           <tr>
                             <th rowspan="2" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/22(Fri.)</th>
                             <tr style="border: 1px solid #000;">
                               <td style=" padding:20px;vertical-align:middle">19:00</td>
                               <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance) </td>
                             </tr>
                           </tr>

                           <!-- 11/23 -->
                          <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/23(Sat.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">12:00-18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（展覽） Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Exhibition)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance)</td>
                               </tr>
                           </tr>

                           <!-- 11/24 -->

                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/24(Sun.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">12:00-18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（展覽） Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Exhibition)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">知覺野人｜《人造太陽與電覺修羅_人造神通與機械肉身_大語言模型與沉浸式生成系統開發計畫》（現場演出）｜60 mins Aisthesis Savage｜Artificial Sun and Electro Asura - Artificial Abhijñā and Mechanical Body: Development Project for Large Language Models and Immersive Generation Systems (Live Performance)</td>
                               </tr>
                           </tr>
                   
                     </div>

                     <!-- ///////// -->
                      <!-- 第五週 -->
                     <!-- 11月 -->
                 <div class="table"  v-if="ShowNovemberfifthweek">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                          </tr>
                                 <!-- 11/29 -->

                           <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">11/29(Fri.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">19:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">20:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                           </tr>
                                    <!-- 11/30 -->
                                    <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">11/30(Sat.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">15:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">16:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;">蕭力綺｜《虛擬的共鳴》（現場演出）｜25 mins HSIAO Li-Chi｜Resonance in the Virtual Realm (Live Performance)</td>
                               </tr>
                                 
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">18:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">19:00</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;border: none;">吳承儒｜《認夢》（現場演出）｜15 mins WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                               </tr>
                           </tr>
                     </div>

                     <!-- ///////// -->

                     <!-- 11月顯示12月節目 -->

                     <div class="table"  v-if="ShowNovember">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">12月節目</td>
                          </tr>

                          <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">12/1(Sun.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins <br>WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins <br>WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                          
                           </tr>
                       
                           </tr>
                        
                           <!-- 12/6 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/6(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">19:30</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x KAMS｜《未來視覺派對 #2》｜60 mins C-LAB x KAMS｜Future Vision Party #2 (Live Performance)</td>
                           </tr>
                      
                         <!-- 12/7 -->
                         <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/7(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">19:30</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x KAMS｜《未來視覺派對 #2》｜60 mins C-LAB x KAMS｜Future Vision Party #2 (Live Performance)</td>
                           </tr>
                           <!-- 12/13 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/13(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">16:00-20:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production </td>
                           </tr>
                           <!-- 12/14 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/14(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">12:00-19:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production  </td>
                           </tr>
                         <!-- 12/15 -->
                          <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color: blue;color: white;">12/15(Sun.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">12:00-19:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production  </td>
                           </tr>

                           <!-- 12/21 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color: blue;color: white;">12/21(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">場次待確認</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">財團法人數位藝術基金會｜《諸神黃昏》｜20 mins Digital Art Foundation｜DOME of the Gods</td>
                           </tr>

                           <!-- 12/22 -->
                          <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">12/22(Sun.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">14:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－清華大學藝術學院學士班 / 科藝所成果呈現》｜60 mins  Immersive Audiovisual Workshop - The Joint Presentation: Interdisciplinary Program of Technology and Art, National Tsing Hua University</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">15:30</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－實踐大學媒傳系成果呈現》｜60 mins Immersive Audiovisual Workshop - The Joint Presentation: Department of Communications Design, Shih Chien University</td>
                               </tr>

                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">17:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－清華大學藝術學院學士班 / 科藝所成果呈現》｜60 mins  Immersive Audiovisual Workshop - The Joint Presentation: Interdisciplinary Program of Technology and Art, National Tsing Hua University</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle;">18:30</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;border: none;border-end-end-radius: 25px;"> 《沉浸式影音工作坊－實踐大學媒傳系成果呈現》｜60 mins Immersive Audiovisual Workshop - The Joint Presentation: Department of Communications Design, Shih Chien University</td>
                               </tr>
                           </tr> 
                     </div>
                     <!-- /////////// -->
                <!-- 10月演出結束 -->
                     <!-- <div class="table" v-if="ShowNovember">
                   <tr>
                     <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                   </tr>


                  <tr>
                   <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">10/22(Tue.)</th>
                   <td scope="col" style="padding:20px;vertical-align:middle">19:00</td>
                   <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;width: 100%;">黃子祐｜《沉浸影像工作坊》Part I <br>Revlis HUANG｜Dome immersive video workshop via TouchDesigner- Part I</td>
                  </tr>


                 <tr>
                   <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">10/25(Fri.)</th>
                   <td scope="col" style="padding:20px;vertical-align:middle">13:30、17:30</td>
                   <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;border: none;width: 100%;border-end-end-radius: 25px;">黃子祐｜《沉浸影像工作坊》Part II <br>Revlis HUANG｜Dome immersive video workshop via TouchDesigner- Part II</td>
                 </tr>
                  </div> -->
                  <!-- ///// -->


                     <!-- 12月Dec -->

                <div class="table"  v-if="ShowDecember">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                          </tr>

                          <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">12/1(Sun.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins <br>WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins <br>WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                          
                           </tr>
                       
                           </tr>
                        
                           <!-- 12/6 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/6(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">19:30</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x KAMS｜《未來視覺派對 #2》｜60 mins C-LAB x KAMS｜Future Vision Party #2 (Live Performance)</td>
                           </tr>
                      
                         <!-- 12/7 -->
                         <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/7(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">19:30</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x KAMS｜《未來視覺派對 #2》｜60 mins C-LAB x KAMS｜Future Vision Party #2 (Live Performance)</td>
                           </tr>
                           <!-- 12/13 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/13(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">16:00-20:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production </td>
                           </tr>
                           <!-- 12/14 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/14(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">12:00-19:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production  </td>
                           </tr>
                         <!-- 12/15 -->
                          <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color: blue;color: white;">12/15(Sun.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">12:00-19:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production  </td>
                           </tr>

                           <!-- 12/21 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color: blue;color: white;">12/21(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">場次待確認</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">財團法人數位藝術基金會｜《諸神黃昏》｜20 mins Digital Art Foundation｜DOME of the Gods</td>
                           </tr>

                           <!-- 12/22 -->
                          <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">12/22(Sun.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">14:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－清華大學藝術學院學士班 / 科藝所成果呈現》｜60 mins  Immersive Audiovisual Workshop - The Joint Presentation: Interdisciplinary Program of Technology and Art, National Tsing Hua University</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">15:30</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－實踐大學媒傳系成果呈現》｜60 mins Immersive Audiovisual Workshop - The Joint Presentation: Department of Communications Design, Shih Chien University</td>
                               </tr>

                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">17:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－清華大學藝術學院學士班 / 科藝所成果呈現》｜60 mins  Immersive Audiovisual Workshop - The Joint Presentation: Interdisciplinary Program of Technology and Art, National Tsing Hua University</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle;">18:30</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;border: none;"> 《沉浸式影音工作坊－實踐大學媒傳系成果呈現》｜60 mins Immersive Audiovisual Workshop - The Joint Presentation: Department of Communications Design, Shih Chien University</td>
                               </tr>
                           </tr> 
                     </div>
                     <!-- //////////// -->

                      <!-- 12月第一週 -->
                <div class="table"  v-if="ShowDecemberfirstweek">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本週節目</td>
                          </tr>

                          <tr>
                             <th rowspan="3" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;">12/1(Sun.)</th>
                       
                           <tr style="border: 1px solid #000;">
                             <td style=" padding:20px;vertical-align:middle">15:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins <br>WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                           </tr>
                        
                           <tr>
                             <td style="padding:20px;vertical-align:middle">16:00</td>
                             <td style="padding:20px;vertical-align:middle;text-align: left;">吳承儒｜《認夢》（現場演出）｜15 mins <br>WU Cheng-Ru｜Dream Percept (Live Performance)</td>
                          
                           </tr>
                       
                           </tr>
                        
                           <!-- 12/6 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/6(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">19:30</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB x KAMS｜《未來視覺派對 #2》｜60 mins C-LAB x KAMS｜Future Vision Party #2 (Live Performance)</td>
                           </tr>
                                    <!-- 12/7 -->
                         <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">12/7(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">19:30</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;border-end-end-radius: 25px;">C-LAB x KAMS｜《未來視覺派對 #2》｜60 mins C-LAB x KAMS｜Future Vision Party #2 (Live Performance)</td>
                           </tr>

                     </div>
                     <!-- ////////////// -->
                                        <!-- 第二週 -->
                                        <div class="table"  v-if="ShowDecembersecondweek">
                          <tr>
                             <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                          </tr>
                           <!-- 12/13 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/13(Fri.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">16:00-20:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production </td>
                           </tr>
                           <!-- 12/14 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color:blue;color: white;">12/14(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">12:00-19:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production  </td>
                           </tr>
                         <!-- 12/15 -->
                          <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color: blue;color: white;border-end-start-radius: 25px;">12/15(Sun.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">12:00-19:00</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;border-end-end-radius: 25px;">C-LAB Future Vision Lab 自製作品 C-LAB Future Vision Lab’s latest production  </td>
                           </tr>
                     </div>
                  <!-- ////////////// -->
                                      <!-- 第三週 -->

                                      <div class="table"  v-if="ShowDecemberthirdweek">
                                        <tr>
                                           <td colspan="4"style=" border: none;border-top-left-radius: 25px;border-top-right-radius: 25px; padding:5px;background-color: #000;color: #ffffff;height:50px;vertical-align:middle;font-weight: 700;font-size: 1.2rem;">本月節目</td>
                                        </tr>
                           <!-- 12/21 -->
                           <tr>
                             <th scope="col" style="border-left:none;padding:20px;vertical-align:middle;background-color: blue;color: white;">12/21(Sat.)</th>
                             <td scope="col" style="padding:20px;vertical-align:middle">場次待確認</td>
                             <td colspan="2" scope="col" style="padding:20px;vertical-align:middle;text-align: left;">財團法人數位藝術基金會｜《諸神黃昏》｜20 mins Digital Art Foundation｜DOME of the Gods</td>
                           </tr>

                           <!-- 12/22 -->
                          <tr>
                             <th rowspan="5" scope="col" style="padding:20px;vertical-align:middle;background-color:blue;color: white;border-end-start-radius: 25px;">12/22(Sun.)</th>
                       
                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">14:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－清華大學藝術學院學士班 / 科藝所成果呈現》｜60 mins  Immersive Audiovisual Workshop - The Joint Presentation: Interdisciplinary Program of Technology and Art, National Tsing Hua University</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle">15:30</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－實踐大學媒傳系成果呈現》｜60 mins Immersive Audiovisual Workshop - The Joint Presentation: Department of Communications Design, Shih Chien University</td>
                               </tr>

                               <tr style="border: 1px solid #000;">
                                 <td style=" padding:20px;vertical-align:middle">17:00</td>
                                  <td style="padding:20px;vertical-align:middle;text-align: left;"> 《沉浸式影音工作坊－清華大學藝術學院學士班 / 科藝所成果呈現》｜60 mins  Immersive Audiovisual Workshop - The Joint Presentation: Interdisciplinary Program of Technology and Art, National Tsing Hua University</td>
                               </tr>
                        
                               <tr>
                                 <td style="padding:20px;vertical-align:middle;">18:30</td>
                                 <td style="padding:20px;vertical-align:middle;text-align: left;border: none;border-end-end-radius: 25px;"> 《沉浸式影音工作坊－實踐大學媒傳系成果呈現》｜60 mins Immersive Audiovisual Workshop - The Joint Presentation: Department of Communications Design, Shih Chien University</td>
                               </tr>
                           </tr> 
                     </div>
                    <!-- ////////////// -->



               </div>
                 
       <!--11月結束 ------------------------------------------------------------------------------------------------------------------------------------------------ -->
   
                 
                  <!-- <section class="grids" style="width: 100vw;height: auto;background-color: blue;padding:4rem;"> -->
                        <div class="grid_title">
                         <!-- <div class="title_background">
                           <p style="margin-bottom: 0 !important;">播映作品</p>
                        </div> -->

                        <div class="grids_en" style="text-transform: uppercase;color:#ff8c9f;font-size:10vw;font-family:Impact, fantasy;">2024 WORKS</div>
           
                       <div class="container" >
                          <!-- <div class="row grid max-margin" style="margin: 0 auto;border: 5px orange solid;"> -->
                           <div class="row grid max-margin" style="margin: 0 auto;justify-content: center;position: relative;">
                               <!-- <div class="col-md-6 card mb-80" v-for="(item, index) in products" v-bind:key="index" v-on:click="chosework(index)"> -->
                                 <!-- <div class="col-md-6 card mb-80" v-for="(item, index) in products" v-bind:key="item.id" v-on:click="jump(item.id)">  -->
                                   <div class="col-md-6 card mb-80" v-for="(item, index) in products" v-bind:key="item.id">
                                  <div class="imagewrap">
                                        <div class="imagewrap-background"></div>
                                           <img :src ="'https://unzip-clab-api.clab.org.tw/'+item.photo_1" style="height:100%;width: 100%;object-fit: cover; z-index: 2;">
                                  </div>
                              
                                  
                                      <div class="wordwrap">
                                       <!-- <router-link :to="`FVLhome/Aboutwork/${index}/${item.title_zh}/${item.introduction_zh}`"> -->
                                         <router-link :to="{
                                            name:'Aboutwork',
                                            params:{
                                             id:index,
                                             title:item.work_zh.title,
                                             word:item.work_zh.proposal,
                                             photo:item.photo_1,
                                             photo2:item.photo_2,
                                             photo3:item.photo_3,
                                             // artist:products2,
                                             // artist:item.groups.artists
                                             // artist:this.products2.item.work_zh.note,
                                            }
                                         }">
                                     <p style="color: aliceblue;" > {{ item.work_en.title }}</p>
                                       </router-link>
                                      </div>
                                  
                               </div>
                           </div>
                        </div>
</div>
                  <!-- </section> -->

               

                  <div class="faq_title">
                           <!-- <div class="title_background">
                               <p style="margin-bottom: 0 !important;">問與答</p>
                           </div> -->
                           <div class="grids_en" style="text-transform: uppercase;color:#ff8c9f;font-size:10vw;font-family:Impact, fantasy;">FAQ</div>
                       </div>


                        <div class="faq-container">
                       
                             <div class="box">
                             <div class="content">
                                 <details id="qa1">
                                    <summary>Where is DOME？<hr></summary>
                                 
                                         <div class="answer" style="font-size: medium;">
                                           <p style="text-align: left;">At C-LAB East Lawn, No. 177, Section 1, Jianguo South Road, Da'an District, Taipei City 10655</p>
                                         </div>
                                 </details>
                                 <details id="qa2">
                                    <summary>Can I eat or drink in the DOME?<hr></summary>
                                 
                                         <div class="answer" style="font-size: medium;">
                                           <p>Due to the limited space in the venue, picnic mats and chairs are not allowed. If you have other needs, please contact the front desk staff for assistance.</p>
                                         </div>
                                 </details>
                                 <details id="qa3">
                                    <summary>Can I sit on the ground during the show?<hr></summary>
                                 
                                         <div class="answer" style="font-size: medium;">
                                           <p>Since DOME  has a mirrored floor, it is recommended to wear "trousers" to enter and watch the show.</p>
                                         </div>
                                 </details>
                                 <details id="qa4">
                                    <summary>Can I record or videotape the performance?<hr></summary>
                                 
                                         <div class="answer" style="font-size: medium;">
                                           <p>Photos and live action shots can be taken during the broadcast, but the use of flash is prohibited.</p>
                                         </div>
                                 </details>

                             </div>
                             
                             </div>
     
                        </div>
                        <!-- <div class="border-line" style="position: relative; z-index: 1;">
                      <hr style="color: aliceblue;display: block;margin-top:2rem;">
                     </div> -->

       <!-- <div class="bottom-ul" style="margin-top: 4rem;display:flex;position: relative;flex-direction: column;justify-content:flex-start;width: 100%;margin-bottom: 4rem;">
         <ul  style="display: flex;width: 100%; justify-content: space-evenly;">
           <li><a href="https://vuejs.org/v2/guide/">DOME 1.0</a></li>
           <li><a href="https://bulma.io/documentation/overview/start/">DOME 2.0</a></li>
           <li><a href="https://buefy.github.io/#/documentation/start">沈浸式場域</a></li>
           <li><a href="https://open-call.clab.org.tw/brd/fvl-2024/apply">徵件系統</a></li>
         </ul>
        </div> -->
 
        <info-bar></info-bar>
       <foot-bar></foot-bar>
 </section>

</section>
</template>

<script>
// import "../jquery.js";
import axios from 'axios';
import '../parallaxedEffect.js';
import {ThreeJs} from '../components';
import { FootBar } from '../components';
import { InfoBar } from '../components';
import {gsap} from "gsap";
import{Anibackground}from '../components';
import { BannerBackground } from '../components';
import {Background} from '../components';
import {BackGround2}from '../components';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { TextPlugin } from 'gsap/dist/TextPlugin.js';
// import { BooleanKeyframeTrack, LineLoop, LoopRepeat, RedFormat } from 'three';
import { defineComponent } from 'vue';
import {FvlSVG} from'../assets/2024fvl.svg';
import Title from '../components/Title.vue';
import { Camera } from 'three';


let timer 
const interval = 4000;


// import 'font-awesome/css/font-awesome.min.css';
// import 'font-awesome/css/font-awesome.css';
// import 'font-awesome/fonts/fontawesome-webfont.eot';
// import 'font-awesome/fonts/fontawesome-webfont.svg';
// import 'font-awesome/fonts/fontawesome-webfont.ttf';
export default defineComponent({
 name: 'Fvlhome',
 components: {
    'three-js':ThreeJs,
    'foot-bar':FootBar,
    'info-bar':InfoBar,
    'ani-back':Anibackground,
    'banner-background':BannerBackground,
    'back-ground':Background,
    'back-ground2':BackGround2,
    'fvl-svg':FvlSVG,
    'ti-tle':Title,
 },
 
 
 data () {
   return {
     dateArray:[],
     slideData:[],
     show:true,
     type:'All',
     currentMonth:{},
     currentDate:{},
     products:[],
     products2:[],
     startMonth:10,
     secondMonth:11,
     endMonth:12,
     // startDate:30,
     // endDate:26,
     productData : {
     },
     banner:"../assets/2024banner.png",
     link:'early-bird',
     // tickets:[
     // {early:'$200'},
     //  {pre:'$180'},
     //  {normal:'$220'},
     //  {student:'$195'}
     // ],
     transitionName:'left-in',
     show:0,
     msg: 'A quick-start vue.js frontend development template.',
     imgs:[
       {type:'img',src:'./imgs/1.jpeg'},
     ],
   }
 },

 

 methods:{
   // jump(id){
   //   this.$router.push(
   //     { name:item.link,
   //      query:{id}}
   //      );
   // },
   chosework(index){
         // console.log(item);
         // for (var i = 0; i < this.products.length; i++) {
         // console.log(i);
         console.log(index)

       // }
         
   },
    
   plus(){
  console.log('已選擇預售票');

   },

   setShow(index){
       this.show = index 
     },
     nextShow(){
       this.show ++
     },

     slideCtrl(slidesToShow = 1){
       if(slidesToShow > 0 ){
         const shiftItem = this.slideData.shift();
         this.slideData.push(shiftItem);
         return;
       }
       if(slidesToShow <0){
         const shiftItem = this.slideData.pop();
         this.slideData.unshift(shiftItem);
       }
     },
      
},
 watch:{
   show(nVal,oVal){

         if(nVal<0){
       
         this.show = this.imgs.length -1 
         }else if(nVal >this.imgs.length -1){
    
           this.show = 0
         }else{
           if(oVal < 0 )this.transitionName = 'left-in'
           else if (oVal > this.imgs.length -1 )this.transitionName = 'right-in'
           else  this.transitionName = nVal>oVal ?'right-in' : 'left-in'
         }
   }
 },
  
 
onMounted(){
 parallaxie('.back-image.parallaxie');
},
 mounted(){
   console.log(this.currentMonth)
   console.log(this.$route)
  console.log(this.dateArray)


// ----------自動輪播----------------
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

// const shut = document.getElementsById('shut');

// gsap.to('#shut',{
//      top:-6,
//      duration:0.01,
//      scrollTrigger:{
//       trigger:'.fvl_icon',
//       start:"top 50%",
//       end:"top 30%",
//       toggleActions:'play reverse restart reverse',
//       // markers:{
//       //   startColor:"orange",
//       //    endColor:"blue",
//       //   fontSize:"2rem"
//       // }
//     }
// }),
gsap.to('.banner',{
    scrollTrigger:{
     pin:true,
     trigger:'.banner-box',
     start:"bottom 100%",
     end:"+=800 0%",
     // toggleActions:'play reverse restart reverse',
     // markers:{
     //   startColor:"orange",
     //    endColor:"blue",
     //   fontSize:"2rem"
     // }
   }
}),
gsap.to('.date-img',{
 opacity:1,
 duration:2,
    scrollTrigger:{
     trigger:'.banner',
     start:"60% 20%",
     end:"60% 0%",
     toggleActions:'play plaue reverse none',
     markers:{
       startColor:"orange",
        endColor:"blue",
       fontSize:"2rem"
     }
   }
}),
gsap.to('.fvl_about',{
    scrollTrigger:{
     pin:true,
     trigger:'.about-background',
     start:"26% 60%",
     end:"+=600 0%",
     // toggleActions:'play reverse restart reverse',
     // markers:{
     //   startColor:"orange",
     //    endColor:"blue",
     //   fontSize:"2rem"
     // }
   }
}),

gsap.to('.background3',{
   left:-20,
   duration:1,
   scrollTrigger:{
     trigger:'.fvl_en',
     start:"bottom 60%",
     end:"bottom 20%",
     //  toggleActions:'play pause restart none',
     toggleActions:'play play reverse none',
     //   markers:{
     //   startColor:"purple",
     //    endColor:"blue",
     //   fontSize:"2rem"
     // }
   }
})


gsap.to('.fvl_about',{
   opacity:1,
   top:20,
   duration:0.1,
   scrollTrigger:{
     trigger:'.banner',
     start:"70% 60%",
     end:"70% 40%",
     //  toggleActions:'play pause restart none',
     //   markers:{
     //   startColor:"orange",
     //    endColor:"blue",
     //   fontSize:"2rem"
     // }
   }
}),

gsap.to('.sales_info',{
   opacity:1,
   top:20,
   duration:0.1,
   scrollTrigger:{
     trigger:'.exibition_info',
     start:"40% 0%",
     end:"bottom 0%",
     //  toggleActions:'play pause restart none',
     //   markers:{
     //   startColor:"orange",
     //    endColor:"blue",
     //   fontSize:"2rem"
     // }
   }
}),

gsap.to('.program-list',{
   opacity:1,
   top:20,
   duration:1,
   scrollTrigger:{
     trigger:'.tickets',
     start:"bottom 60%",
     end:"bottom 40%",
     //  toggleActions:'play pause restart none',
     //   markers:{
     //   startColor:"orange",
     //    endColor:"blue",
     //   fontSize:"2rem"
     // }
   }
}),
gsap.to('.grid_title',{
   opacity:1,
   top:20,
   duration:0.1,
   scrollTrigger:{
     trigger:'.program-list',
     start:"90% 60%",
     end:"90% 40%",
     //  toggleActions:'play pause restart none',
     //   markers:{
     //   startColor:"orange",
     //    endColor:"blue",
     //   fontSize:"2rem"
     // }
   }
}),
gsap.to('.faq_title',{
   opacity:1,
   top:20,
   duration:0.1,
   scrollTrigger:{
     trigger:'.grid_title',
     start:"bottom 60%",
     end:"bottom 40%",
     //  toggleActions:'play pause restart none',
     //   markers:{
     //   startColor:"orange",
     //    endColor:"blue",
     //   fontSize:"2rem"
     // }
   

   }
})

timer = setInterval(this.nextShow,interval),

axios.all([

 // axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works?search=Future%20Vision%20Lab&limit=25',{responseType:'json'}).then((response)=>{
   axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/5?relation=true',{responseType:'json'}),
       // console.log(response.data)}).catch((error) => console.log(error));
       // for(i=0;i<=this.products.lenght;i++){
       //   i=0;
       // }

   axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/5?relation=true',{responseType:'json'})
   // this.artists = response.data.artist
   // console.log(response.data)}).catch((error) => console.log(error));
   ]).then(axios.spread((response1,response2) => {
     this.products = response1.data.works;
     this.products2 = response2.data.artists;

     console.log(this.products);

     console.log(this.products2);
   }))   .catch(err=>{
   console.log(err.response);
   });
 },

computed:{
 ShowOctober(){
  const currentMonth = new Date().getMonth() +1;
 //  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
 //  this.currentDate = currentDate;
  return currentMonth === this.startMonth;
 },
 ShowOctoberweek(){
  const currentMonth = new Date().getMonth() + 1;
   const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
   this.currentDate = currentDate;
  return currentMonth === this.startMonth && this.currentDate >=21 && this.currentDate <=27 ;
 },
 ShowNovember(){
  const currentMonth = new Date().getMonth() + 1;
  this.currentMonth = currentMonth;
 return currentMonth === this.secondMonth;
 },
 ShowNovemberfirstweek(){
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
  this.currentDate = currentDate;
 return currentMonth === this.startMonth && this.currentDate >=28 && this.currentDate <=31 || currentMonth === this.secondMonth && this.currentDate >= 1 &&this.currentDate <=3;
 },

 ShowNovembersecondweek(){
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
  this.currentDate = currentDate;
 return currentMonth === this.secondMonth && this.currentDate >= 4 && this.currentDate <=10;
 },
 ShowNovemberthirdweek(){
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
  this.currentDate = currentDate;
 return currentMonth === this.secondMonth && this.currentDate >= 11 && this.currentDate <=17;
 },
 ShowNovemberfourthweek(){
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
  this.currentDate = currentDate;
 return currentMonth === this.secondMonth && this.currentDate >= 18 && this.currentDate <=24;
 },
 ShowNovemberfifthweek(){
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
  this.currentDate = currentDate;
 return currentMonth === this.secondMonth && this.currentDate >= 25 && this.currentDate <=30;
 },

 ShowDecember(){
  const currentMonth = new Date().getMonth() + 1;
 //  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
 //  this.currentDate = currentDate;
  return currentMonth === this.endMonth;
 },
 ShowDecemberfirstweek(){
  const currentMonth = new Date().getMonth() +1;
  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
 this.currentDate = currentDate;
 return currentMonth === this.endMonth && this.currentDate >= 1 && this.currentDate <=7;
 },
 ShowDecembersecondweek(){
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
 this.currentDate = currentDate;
 return currentMonth === this.endMonth && this.currentDate >= 8 && this.currentDate <=15;
 },
 ShowDecemberthirdweek(){
  const currentMonth = new Date().getMonth() + 1;
  const currentDate = new Date().getDate();
  this.currentMonth = currentMonth;
 this.currentDate = currentDate;
 return currentMonth === this.endMonth && this.currentDate >= 16 && this.currentDate <= 22;
 }
}


 



})

</script>

<style lang="scss" scoped>

.dropbtn{
 background-color:#d00053;
 color: rgb(255, 255, 255);
 padding: 15px;
 width:8rem;
 height: 3rem;
 font-size: 1rem;
 font-weight: 600;
 border: none;
 // border-radius: 30px;
}
.dropdown{
 position:fixed;
 right:10%;
 top: 2%;
 display:inline-block;
 z-index:4;
}
.dropdown-content {
 display: none;
 position: absolute;
 background-color: #f9f9f9;
 min-width: 160px;
 border-radius: 0;
 box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
 z-index:2;
}
.dropdown-content a{
 padding-left:1em;
}
.dropdown-content a:hover {
 background-color: #ff2e77;
 color:white;
}

.dropdown:hover {
 .dropdown-content {
 display:flex;
 flex-direction: column;
}
}
.dropdown:hover .dropbtn {
 background-color:#d00053;
}

.banner-box{
 height: 100vh;
 width:100vw;
 margin: 0 auto;
 position:relative;
 // margin-top:4rem;
}
.banner{
 height: 100%;
 width:100vw;
 object-fit: cover;
 overflow: hidden;
 z-index: 1;
 position:relative;
}
.banner-img{
position:relative;
width: 100vw;
height:100%;
}
.pulse{
position: relative;
width: 300px;
height: 300px;
background: rgb(255, 40, 140);
border-radius: 50%;
left:62%;
top:32%;
z-index: 0;
filter: blur(5px);
}
.pulse span{
 position: absolute;
 width: 100%;
 height: 100%;
 opacity:.8;
 background:inherit;
 border-radius:inherit;
 animation: pulseAnimation 4s ease-out infinite;
 animation-delay: calc(1s * var(--i));
}
.table{
 font-size:1em;
 width: 100%;
 border: none !important;
 border-bottom: none; 
  text-align: center;
   border-collapse:separate;
   border-radius: 25px;
   width: 100%;
}
.table td{
 background-color: white;
}
@keyframes pulseAnimation{
 100%{
   opacity: 0;
   transform: scale(2);
 }
}
.banner-back{
 // position: absolute;
 top: 0;
 // z-index: -1;
}
.fvl-logo-img{
 position: absolute;
 top: 10%;
 z-index: 1;
 width: 60%;
 height: auto;
 display: flex;
 align-items: center;
}
// .banner{
//   position: relative;
//   width:100%;
//   // height:300px;
//   height: 100vh;
//   margin: 0 auto;
//   overflow:hidden;
//   object-fit: cover;
// }

// .buble{
//   z-index: 999;
//   background-color: #000000;
//   // pointer-events:none;
//   width:20px;
//   height:20px;
//   border-radius: 100px;
//   position: absolute;
//   pointer-events: none;
//   clip-path: circle(100px at var(--x,));
//   // transform: translate(-50%,-50%);
//   transition: 0.05s;
//   // border: px rgb(255, 255, 255) solid;
//   top:0;
//   left: 0;
//   // animation: colorgen 5s infinite;
// }

// @keyframes colorgen {
//   0% {
//     opacity: 1;
//     filter: hue-rotate(0deg);
//   }
//   100% {
//     opacity: 1;
//     filter: hue-rotate(360deg);
//   }
// }
// .banner-box:hover{
//   #{
// transform: scale(2,2);
// filter: blur(5px);
// opacity: 0.9;
// }
// }



@media only screen and (min-width:850px) {
 .banner-box{
   margin: 0 auto;
   position: relative;
   height:100vh;
   overflow: hidden;
   width: 100vw;
   margin-top: 4rem;
 }
 
   .banner {
       // height:600px;
       height: 100%;
       width: 100%;
   }
   .banner.img{
     height: 100%;
     width: 100%;
   }
   .banner-back{
   position: absolute;
   height:100%;
   top: 0;
   z-index: -1;
 }
    .pulse{
   width: 250px;
   height: 250px;
   left:62%;
   top:68%;
 }
 }

 @media only screen and (max-width:1024px) {
   .section {
      padding: 3rem 1rem;
   }
   .pulse{
   width: 300px;
   height: 300px;
   left:55%;
   top:55%;
 }
 }

 @media only screen and (min-width:1024px) {
   .section {
      padding: 3rem 0rem;
   }
   .pulse{
   width: 300px;
   height: 300px;
   left:60%;
   top:30%;
 }
   
 }
 @media only screen and (min-width:1280px) {
   .pulse{
   width: 300px;
   height: 300px;
   left:62%;
   top:30%;
 }
   
 }

 // @media screen and (max-width: 850px) {
 //   .banner-box{
 //     height:100%;
 //   }
 // }
 

.page{
 position: absolute;
 display: flex;
 justify-content: center;
 width: 100%;
 height:100%;
 overflow-x: hidden;

}
.right-in-enter{
   left: 100%;
}
.right-in-enter-active,.right-in-leave-active{
  transition: left 0.5s;
}
.right-in-enter-to,.right-in-leave{
    left: 0%;
}
.right-in-leave-to{
    left:-100%;
}

// -----------------------------
.slider-btn{
 position:absolute;
 top:50%;
 opacity: 0.8;
 // z-index:999;
 height:10%;
 width: 50px;
 border: none;
 outline:0;
 cursor:pointer;

}
.slider-btn-left{
 left:10px;
background-color: transparent;
}
.slider-btn-right{
 right:10px;
  background-color: transparent;
}
.slider-icon:hover{
   border-color:rgb(97, 97, 97)
}
.slider-icon{
   display: inline-block;
   width: 20px;
   height: 20px;
   border-left:3px solid rgb(255, 255, 255);
   border-bottom:3px solid rgb(255, 255, 255);
   transition:border 2s;
}
.slider-icon-left{
 transform:rotate(45deg);
}
.slider-icon-right{
   transform:rotate(-135deg)
}
// ------left----------

.left-in-enter{
   left: -100%;
}
.left-in-enter-active,.left-in-leave-active{
  transition: left 2s;
}
.left-in-enter-to,.left-in-leave{
    left: 0%;
}
.left-in-leave-to{
    left:100%;
}




// ------left----------
.right-arrow{
 border: solid black;
 border-width: 0 3px 3px 0;
 display: inline-block;
 padding: 3px;
 transform: rotate(-45deg);
 -webkit-transform: rotate(-45deg);
}
// --------------banner----------




.additional-bar {
 padding: 15px;
}
.gh-btn {
 background-color: #232323;
 background-repeat: no-repeat;
 border: 1px solid #d5d5d5;
 border-radius: 4px;
 color: #333;
 text-decoration: none;
 text-shadow: 0 1px 0 #fff;
 white-space: nowrap;
 cursor: pointer;
}
.image-container{
 margin-bottom: auto;
 background-color: aqua;
 
}

.gdlr-core-title-item-title{
   animation: fade 0.5s linear;
}
@keyframes fade {
   from {
       opacity: 0;
   }
   to {
       opacity: 1;
   }
}
.gdlr-core-title-item-caption{
 animation: fade 0.5s linear;
}
@keyframes fade {
   from {
       opacity: 0;
   }
   to {
       opacity: 1;
   }
}
.about-future-vision{
 animation: fade 0.5s linear;
}
@keyframes fade {
   from {
       opacity: 0;
   }
   to {
       opacity: 1;
   }
}
.gdlr-core-text-box-item-content{
 animation: fade 0.5s linear;
}
@keyframes fade {
   from {
       opacity: 0;
   }
   to {
       opacity: 1;
   }
}




.flex-col{
 flex-direction:column
}
.md\:flex{
 display:flex;
}
.grid-cols-1{
 grid-template-columns:repeat(1, minmax(0, 1fr));
}
.grid-cols-2{
 grid-template-columns:repeat(2, minmax(0, 1fr));
}
.md\:grid-cols-5{
 // grid-template-columns:repeat(5, minmax(0, 1fr));
 grid-template-columns:repeat(2, minmax(0, 1fr));
}
.gap-4{
 gap:1rem;
}
.grid-cols-1{
 grid-template-columns:repeat(1, minmax(0, 1fr));
}
.relative{
 position:relative
}
.grid{
 display: grid;
}
.mb-8{
 margin-bottom:2rem;
}
.rounded-xl{
 border-radius:0.75rem;
}
.img{
 // display:block;
 vertical-align: middle;
}
.img{
 max-width:100%;
 // height:auto;
}
.md\:hidden{
 display:grid;
}
.hidden{
 display: none;
}



.preview {
 margin: 2rem auto 0 auto;
 width: 100%;
 height:auto;
 background:transparent;
 background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/87db0364-e198-4abb-b4e9-d6c3c70ebd68/RFNDXzMyNjQuanBlZy0xNjMzMTY4NDQzNzYwLTY5NjE=.jpeg);
 background-size: cover;
 background-position: top center;
 background-repeat: no-repeat;
 padding: 5.5rem 2.4rem;
 // border-radius: 0.8rem;
 display: flex;
 object-fit: cover;
 flex-direction: column;
 align-items: center;
 // overflow: hidden;
 // animation:bottomIn 1s linear 0s;
}
// @keyframes bottomIn {
//     from {
//         transform: translate3d(30%, 0%, 0);
//     }

//     to {
//         transform: none;
//     }
// }

.preview2 {
 margin: 2rem auto 0 auto;
 width: 100%;
 height: auto;
 background:transparent;
 background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/a1a593a6-c6ba-4c8f-8663-1ee9d72252d3/Q29weSBvZiBEb21lX0EuanBnLTE2MzMxNzEyMDA3MDctMzE0Mw==.jpeg);
 background-size: cover;
 background-position: top center;
 background-repeat: no-repeat;
 padding: 5.5rem 2.4rem;
 // border-radius: 0.8rem;
 display: flex;
 flex-direction: column;
 object-fit: cover;
 align-items: center;
 overflow: hidden;
 // animation:bottomIn 2s linear 0s;
}

.preview3 {
 margin: 2rem auto 0 auto;
 width: 100%;
 height: auto;
 background:transparent;
 background-image: url(https://unzip-clab-api.clab.org.tw/work-photo/a80d9658-dbf7-45be-bb50-9642899f6212/56ys5LqM57WELmpwZy0xNjMzMTY4NzM5OTI1LTU1Mzc=.jpeg);
 background-size: cover;
 background-position: top center;
 background-repeat: no-repeat;
 padding: 5.5rem 2.4rem;
 // border-radius: 0.8rem;
 display: flex;
 flex-direction: column;
 object-fit: cover;
 align-items: center;
 overflow: hidden;
 // animation:bottomIn 3s linear 0s;
}
.preview__text{
 display: flex;
 flex-direction: column;
 align-items: flex-start;
}
.preview__text__link__btn{
 background-color: #232323;
 border-radius: 30px;
 transition:all 0.3s linear;
 color: aliceblue;

}
.preview__text__link__btn:hover{
 background-color: rgb(236, 236, 236);
}
.default-btn{
 text-transform:uppercase;
 font-weight:600;
 font-size: 0.8rem;
 line-height:0.5rem;
 letter-spacing: 0.1rem;
 padding:0.7rem 0.7rem;
 border: none;
 cursor: pointer;
 margin-top: 1rem;
}
.default-btn:hover{
 color: #232323;
}
label {
 background: #CCC;
 display: inline-block;
 cursor: pointer;
 width: 10px;
 height: 10px;
 border-radius: 5px;
 margin-left: 5px;

}
label:hover{
 background-color: #333;
}
input[type=checkbox]:checked{
   label{
     background:black;
   }
}

// -------------slider2----------------
.slide{
   width: 100%;
   overflow: hidden;
}
.slide-list{
 display: flex;
 list-style-type: none;
 padding: 0;
}
.slide-article{
 background-color: #eee;
 padding-top: 10px;
 height: 100%;
 margin: 10px;
 border-radius: 10px;
}
.slide-ctrl{
 margin-top: 3rem;
display: flex;
align-items: center;
justify-content: center;
}
.slide-prev,
.slide-next{
  width: 150px;
  display: inline-block;
  background-color: #c0c0c0;
  color: #fff;
  margin: 0 10px;
  padding:5px 15px;
  border-radius: 50px;
  cursor: pointer;
  &:hover{
   color: rgb(68, 68, 68);
  }

}
.square{
 width: 150px;
 height: 150px;
 background-color: coral;
}
.early-bird{
 width: 10em;
 height: 3em;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius:30em;
 box-shadow: 6px 6px 12px #c5c5c5;
 font-family: inherit;
 font-weight: 900;
 cursor: pointer;
 overflow: hidden;
 background-color:transparent;
 border:none;
 padding:25px;
 margin:0.5rem;
 position: relative;
}
.early-bird::before{
 content: '';
 width: 0;
 height:100%;
 border-radius: 30em;
 position: absolute;
 top: 0;
 left: 0;
 // background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
 transition: .5s ease;
 display: block;
 z-index: -1;
 background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
}
.early-bird:hover::before{
 width: 10em;
}

// .early-bird:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }
.early-bird:hover{
 color: white;
}

.pre-sale{
 width: 9em;
 height: 3em;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius:30em;
 box-shadow: 6px 6px 12px #c5c5c5;
 font-family: inherit;
 font-weight: 900;
 cursor: pointer;
 overflow: hidden;
 background-color:transparent;
 border:none;
 padding:25px;
 margin:0.5rem;
 position: relative;
}
.pre-sale::before{
 content: '';
 width: 0;
 height:100%;
 border-radius: 30em;
 position: absolute;
 top: 0;
 left: 0;
 background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
 transition: .5s ease;
 display: block;
 z-index: -1;
}
.pre-sale:hover::before{
 width: 9em;
}
// .pre-sale:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }
.pre-sale:hover{
 color: white;
}

.reqular-tickets{
 width: 9em;
 height: 3em;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius:30em;
 box-shadow: 6px 6px 12px #c5c5c5;
 font-family: inherit;
 font-weight: 900;
 cursor: pointer;
 overflow: hidden;
 background-color:transparent;
 border:none;
 padding:25px;
 margin:0.5rem;
 position:relative;
}
.reqular-tickets::before{
 content: '';
 width: 0;
 height:100%;
 border-radius: 30em;
 position: absolute;
 top: 0;
 left: 0;
 // background-image: linear-gradient(to right,#0fd850 0%, #f9f047 100%);
 background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
 transition: .5s ease;
 display: block;
 z-index: -1;
}
.reqular-tickets:hover::before{
 width: 9em;
}
.reqular-tickets:hover{
 color: white;
}

// .reqular-tickets:hover{
//   background-color: #232323;
//   color: rgb(0, 255, 170);
// }
.students-tickets{
 width: 9em;
 height: 3em;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius:30em;
 box-shadow: 6px 6px 12px #c5c5c5;
 font-family: inherit;
 font-weight: 900;
 cursor: pointer;
 overflow: hidden;
 background-color:transparent;
 border:none;
 padding:25px;
 margin:0.5rem;
 position: relative;
}
.students-tickets::before{
 content: '';
 width: 0;
 height:100%;
 border-radius: 30em;
 position: absolute;
 top: 0;
 left: 0;
 // background-image: linear-gradient(to right,#0fd850 0%, #f9f047 100%);
 background-image: linear-gradient(to right,#2530ff 0%, #ff0606 80%);
 transition: .5s ease;
 display: block;
 z-index: -1;
}
.students-tickets:hover::before{
 width: 9em;
}
.students-tickets:hover{
 color: rgb(255, 255, 255);
}
.about-background{
 background:linear-gradient(to bottom, #ff2e77 10%,transparent 100%);
 // box-shadow: 0 0 100px 20px #f8536e inset;
 position: relative;
 // box-shadow:0 0 30px 30px blue ;
}
.english_btn{
 border: none;
 border-radius: 30px;
 background-color: black;
 color: #ffffff;
 position: absolute;
 right:10rem;z-index: 999;
 top:2rem;
 width: 5rem;
 height: 2rem;
}
.english_btn:hover{
 border: none;
 border-radius: 30px;
 background-color: rgb(255, 255, 255);
 color: #000000;
 position: absolute;
 right:10rem;z-index: 999;
 top:2rem;
 width: 5rem;
 height: 2rem;
}
.fvl_about{
 width:90vw;
 height:100vh;
 margin: 0 auto;
 display: flex;
 padding:2rem;
 position: relative;
 top: -6%;
 z-index: 2;
 flex-direction: column;
 align-items:flex-start;
 justify-content: center;
 padding: 5rem;
 transition: 0.6s ease-in;
 opacity: 0;
}
.fvl_en{
 box-sizing: border-box;
 color:#ffca8c;
 font-size:9rem;
 font-weight: 700;
 line-height: 1;
 text-transform: uppercase;
 font-family:Impact, fantasy
 ;text-align:left;
 padding-bottom:1rem;
 word-wrap:break-word;
}
.fvl_icon{
 width:40%;
 height:100%;
 margin-right:2rem;
}
.eyes{
 width:10rem;
  height:6rem;
  background-color:black;
  position: relative;
  overflow: hidden;
}
.eye_ball{
 width:80%;
 height: 100%;
 background-color:white;
 border-radius:100% 0;
 transform:rotate(32deg);
 position: absolute;
 left:20%;top:43%;
 overflow: hidden;
 border: 2px black solid;
}
.ball{
 width:60%;
 height: 50%; 
 border: 20px solid black;
 border-radius:50%;
 position: absolute;
 top:30%;
 left: 30%;
 transform: rotate(-30deg);
}
.reflect{
 width: 15px;
 height: 15px;
 border-radius:50%;
 background-color:white;
 position: absolute;
 top: -400%;
 left: 60%;
}
.about_fvl{
 color: white;
 width:100%;
 height: auto;
 background: rgba(255, 255, 255, 0.2);
 border-radius:25px;
 box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
 backdrop-filter: blur(5px);
 -webkit-backdrop-filter: blur(5px);
 border: 1px solid rgba(255, 255, 255, 0.3);
 padding: 2em;
}
.fvl_tickets{
 width: 100vw;
  height:auto;
  margin-top: 1rem;
  position:relative;
  padding-bottom: 4em;
}
.exibition_info{
 width: 100%;
 height:auto;
 margin: 0 auto;
 display: flex;
 flex-direction:column;
 justify-content: center;
 align-items: center;
}
.sales_info{
 width: 40%;
 top: -6%;
 opacity: 0;
 height: auto;
 z-index: 2;
 // margin-top: 1rem;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-bottom: 4rem;
 position:relative;
 transition: 0.6s ease-in;
}
.title_background{
 width:10rem;
 height:auto;
 box-sizing:border-box;
 border-radius: 30px;
 background-color: black;
 color: aliceblue;
 top:0;
 display: flex;
 justify-content: center;
 align-items: center;
 border: none;
 padding: 5px;
 margin-bottom:0;
 font-size: 2rem;
 font-weight: 700;
}

.about_event{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 text-align: center;
 margin-top: 1rem;
 padding-bottom: 2rem;
 width: 100%;
 height: 100%;
 font-size:1.5rem;
 font-weight:bolder;
 color: rgb(255, 255, 255);
}
.event_date{
 display: flex;
 flex-direction:column;
 flex-wrap: wrap;
 font-weight:bolder;
 color: rgb(255, 255, 255);
}
.date{
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 color: white;
}
.hash-tags{
   display: flex;flex-direction: row;
}
.tickets{
 width: 80%;
 height: auto;
 position: relative;
 z-index: 2;
 background: white;
 padding: 2.5em;
 border-radius: 25px;
}
.ticket_buttons{
 top: 0;
 display: flex;
 justify-content:center;
 align-items: center;
 flex-wrap: wrap;
 margin:0.5rem;
}



.tickets_info{
 width:100%;
 height: auto;
 display: flex;
 flex-direction:row;
 justify-content:flex-start;
  display: flex;
  flex-direction:column;
  margin-top: 2rem;
  padding: 1rem;
}

.ticket-price-animate{
 animation: price-enter 0.5s linear;
}
.ticket-time-animate{
 animation: time-enter 0.5s linear;
}
.sales-enter-animate{
 animation: sales-enter 0.5s linear;
}
//////ticket-info-enter-animation
.early-bird-price{
 animation: enter-in 1s linear;
}
.pre-sale-price{
 animation: enter-in 1s linear;
}
.regular-price{
 animation: enter-in 1s linear;
}
.student-price{
 animation: enter-in 1s linear;
}
.early-bird-time{
 animation: enter-in 1s linear;
}
.pre-sale-time{
 animation: enter-in 1s linear;
}
.regular-time{
 animation: enter-in 1s linear;
}
.student-time{
 animation: enter-in 1s linear;
}
@keyframes enter-in {
 0% {opacity: 0;margin-left: -2rem;}
 100%{opacity: 1;margin-left: 0;}
}
@keyframes price-enter{
 0%{opacity: 0;margin-left: -2rem;}
 100%{opacity: 1;margin-left: 0;}
}
@keyframes time-enter{
 0%{opacity: 0;margin-left: -2rem;}
 100%{opacity: 1;margin-left: 0;}
}
@keyframes sales-enter{
 0%{opacity: 0;margin-left: -2rem;}
 100%{opacity: 1;margin-left: 0;}
}
//////

.ticket-price{
 display: flex;
 flex-direction:row;
 width:100%;
}
.ticket-time{
display: flex;
flex-direction:row;
width:100%;
}
.sales-info{
 display: flex;
 flex-direction:row;
 width:100%;
}
.notice{
 font-size:1rem;
 font-weight:lighter;
 color: black;
 margin-top: 1rem;
 line-height:1.5;
 letter-spacing: .5px;
}
.background2{
 width: 100vw;
 height:100vh;
 position: absolute;
 top:0;
 overflow: hidden;
 z-index:1;
}
.background3{
 width: 100%;
 height:100%;
 position: absolute;
 bottom:0;
 overflow: hidden;
 z-index:2;
 opacity:1;
 left:-30%;
 pointer-events: none;
}
.program-list{
 display:flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 width:80%;
 height:auto;
 position: relative;
 margin: 0 auto;
 text-align: center;
 margin-top: 6rem;
 z-index: 1;
 opacity: 0;
 top: -6%;
 transition: 0.8s ease-in;
}
.grid_title{
 display: flex;
 flex-direction:column;
 justify-content: center;
 align-items:center;
 width: 80%;
 height: auto;
 position: relative;
 margin: 0 auto;
 margin-top:4rem;
 z-index: 1;
 opacity: 0;
 top: -6%;
 transition: 0.8s ease-in;
}
.faq_title{
 display: flex;
 flex-direction:column;
 justify-content: center;
 align-items:center;
 width: 80%;
 height: auto;
 position: relative;
 margin: 0 auto;
 margin-top:6rem;
 z-index:2;
 opacity:0;
 top: -6%;
 transition: 0.8s ease-in;
}
.container{
 width:100%;
 height: auto;
 position: relative;
 margin:0 auto;
}
.faq-container{
 width:90%;
 height:auto;
 text-align: center;
 position: relative;
 margin: 0 auto;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding:2rem;
 margin-top: 1rem;
 margin-bottom:5rem;
 z-index: 1;
}
.box{
 width:92%;
 border-radius:25px;
 padding: 2.5rem !important;
}
.content{
 width: 100%;
 height: auto;
 text-align: left;
 font-size:1.5rem;
 font-weight: bolder;
}

.row{
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   justify-content:flex-start;
   align-items: center;
   padding: 1rem 1rem;
}

.card{
 position: relative;
 overflow: hidden;
 display: flex;
 background: #ffffff;
 width:250px;
 height:250px;
 margin:10px;
 flex-direction: column;
 justify-content: center;
 flex-wrap: wrap;
 align-items: center;
}
.imagewrap{
 width:100%;
 height:100%;
 position: absolute;
 top: 0;
 overflow: hidden;
}
.imagewrap img{
 transition:transform 0.8s;
}
.imagewrap-background{
 position: absolute;
 background-color:black;
 width: 100%;
 height: 100%;
 z-index: 3;
 opacity: 0;
}
.card:hover{
 //  .imagewrap{
 //     height: 80%;
 //  }
 .imagewrap-background{
 opacity: 0.5;
 }
 .wordwrap{
   transition: 1s ease-in-out;
   opacity: 1;
   color: white !important;
   position: absolute;
   z-index: 999;
 }
 .imagewrap img{
   transform: scale(1.2);
 }
}

.mb-80{
  margin-bottom:5px;
}
.wordwrap{
 width:100%;
 height:20%;
 opacity: 0;
 padding:2px;
 position: absolute;
 top:40%;
 color: rgb(255, 255, 255) !important;
 font-weight: bolder;
 text-align: center;
 display: flex;
 font-size:1rem;
 justify-content: center;
 align-items: center;
 padding: 10px;
}
// .wordwrap:hover{
//    font-size: 1rem;
// }
.wordwrap:hover{
 color: rgb(255, 255, 255) !important;
}

.card:after{
 content: '';
   width: 0%;
   height: 1px;
   border: 1.5px rgb(0, 255, 170) solid;
   background-color: #000;
   position: absolute;
   bottom:0;
   left: 0;
   transition: all .3s linear;
   z-index: 999;
}

.card:hover::after{
  width: 100%;
}


.shut{
 width:200px;
 height:80px;
 transform: rotate(-30deg);
 position: absolute;
 border-radius:40%;
 background-color: #000;
 top: -65%;
 left:-50%;
 z-index: 999;
 transition: 0.5s ease-in-out;
}

// .eyes:hover{
//   .shut{
//  top:-6%;
//   }
// }


summary{
  margin-bottom: 1rem;
  outline: none;
  position: relative;
  font-size: 1.2rem;
}

details >summary::before{
  position: absolute;
  right: 20px;

 }
 details{
  margin-bottom: 2rem;
  
 }

 details[open] summary ~ * {
 animation: sweep .5s ease-in-out ;
}


@keyframes sweep {
 0%    {opacity: 0; margin-left: -10px}
 100%  {opacity: 1; margin-left: 0px}

}

.forwords{
 background-color: springgreen;
}

@media only screen and (max-width:768px){
.banner-box{
 height: 400px;
 // top: 0px !important;
}
.banner-img{
 transform: scale(1.5);
 right: 6rem;
}
.fvl_about{
 align-items: flex-start;
}
.hash-tags{
   flex-direction: column;
 }
 .pulse{
   width: 200px;
   height: 200px;
   left: 58%;
   top: 20%;
 }
 .table{
 font-size: 0.7em;
}
}

@media only screen and (max-width:720px) {
 .banner-box{
   width: 100vw;
   height: 290px;
   overflow: hidden;
   margin: 0 auto;
   position: relative;
   margin-top:4rem;
 }
 .banner-back{
   height:80%;
   overflow: hidden;
   max-width: 100%;
 }
 .fvl_en{
   font-size: 5rem;
 }
 .pulse{
   width: 100px;
   height: 100px;
   left: 60%;
   top: 40%;
 }
 .fvl-logo-img{
   width: 80%;
   top:20%;
 }
 .title_background{
   font-size: 1rem;
   height: 2rem;
   width: 5rem;
 }
 .hash-tags{
   flex-direction: column;
 }
 .ticket_buttons{
   flex-direction: column;
 }
 .fvl_about{
   flex-direction: column;
   justify-content: center;
   align-items:flex-start;
   // width: 100% !important;
   height: auto;
   padding:1rem;
   padding-top: 3rem;
 }
 .fvl_icon{
   margin-bottom: 2rem;
 }
 .tickets{
   width: 80%;
 }
 .date{
   justify-content: center;
 }
 .early-bird{
   width: 10em;
   height:4em;
 }
 .early-bird:hover::before{
   width:10em;
 }
 .pre-sale{
   width: 10em;
   height:4em;
 }
 .pre-sale:hover::before{
   width:10em;
 }
 .reqular-tickets{
   width: 10em;
   height:4em;
 }
 .reqular-tickets:hover::before{
   width:10em;
 }
 .students-tickets{
   width: 10em;
   height:4em;
 }
 .students-tickets:hover::before{
   width:10em;
 }
 .about_fvl{
   width: 100% !important;;
   height:100%;
 }
 .early-bird-price{
   margin-left: 1rem;
 }
 .early-bird-time{
   margin-left: 1rem;
 }
 .pre-sale-time{
   margin-left: 1rem;
 }
 .regular-time{
  margin-left:1rem

 }
 .student-time{
   margin-left: 1rem;
 }
 .sales-detail{
   font-size: 0.8rem;
   margin-left: 1rem;
 }
 .background2{
   width: 100vw;
   height: auto;
 }
 .table{
 font-size: 0.7em;
}
 .mb-80{
   margin-bottom: 30px !important
 }
 .container{
   width: 100%;
 }
 .card{
   width: 100%;
 }
 .imagewrap{
   width: 100%;
 }
 .faq-container{
   width: 100%;
   padding:0.5rem;
 }
}




// .early-bird.clicker{
// .ticket-price{
//   animation: test 5s linear;
// }
// @keyframes test{
//   0% {opacity: 0;}

//   100%{opacity:1}
// }

// }






// -------------slider2----------------


</style>


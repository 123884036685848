<template>

<date-title></date-title>

</template>

<script>
import Datetitle from './icons/Datetitle.vue';

export default{
    components:{
       'date-title':Datetitle,
    }
}

</script>


<style scoped>
svg {
  cursor: pointer;
}

</style>